module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-posthog/gatsby-browser.js'),
      options: {"plugins":[],"apiKey":"phc_Udte967WZ9bL0MAzK1BVAgZxe4AUDtXvRErVMaWSpKn","head":true,"isEnabledDevMode":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","name":"8TOBER","short_name":"8TOBER","start_url":"/","background_color":"#001a19","theme_color":"#001a19","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f79b521bb3148d226802c8e1df0ba776"},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.googleapis.com","https://fonts.gstatic.com"],"web":[{"name":"Playwrite DK Loopet","file":"https://fonts.googleapis.com/css2?family=Playwrite+DK+Loopet:wght@100..400&display=swap"}]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
